import { Button as AntDButton } from "antd"
import styled from "styled-components"

const Button = styled(AntDButton)`
  box-sizing: border-box;
  display: block;
  height: 2rem;
  padding: 5px 11px;
  cursor: pointer;
  background-color: ${props => props.theme.color.bgColor.pinkColor3};
  font-weight: bold;
  color: ${props => props.theme.color.bgColor.whiteColor1};
  border: 0px solid ${props => props.theme.color.bgColor.whiteColor1};
`

export { Button }
