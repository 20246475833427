import React, { useState, useEffect } from "react"
import propTypes from "prop-types"
import { navigate } from "gatsby"
import styled from "styled-components"
import { Form, Input, MailFilled, Button } from "../../lib/ui"
import { SignUp, CheckSurvey } from "../../services"
import { setCookie, removeCookie, getCookie } from "../../utils/cookie"
import { message } from "antd"

const { Item: AntDFormItem } = Form
const Item = styled(AntDFormItem)`
  width: 100%;
  div.ant-form-item-explain {
    color: #fff !important;
  }
`

const LoginForm = ({ beforeSubmit }) => {
  const [isLoggedIn, SetLogin] = useState(false)
  useEffect(() => {
    const CheckSurveyStatus = async () => {
      const response = await CheckSurvey()
      if (response.status === 200) {
        console.log(response)
        return response.data.surveyCompleted
      }
      return false
    }
    const redirectToSurvey = async () => {
      if (isLoggedIn) {
        const SurveyCompleted = await CheckSurveyStatus()
        console.log("SurveyCompleted", SurveyCompleted)
        if (SurveyCompleted) {
          // navigate("/user/second-survey")
          typeof window !== "undefined" &&
            window.location.assign("/user/second-survey")
        } else {
          // navigate("/user/survey")
          typeof window !== "undefined" &&
            window.location.assign("/user/survey")
        }
      }
    }
    redirectToSurvey()
  }, [isLoggedIn])

  const onFinish = async values => {
    if (!beforeSubmit()) return
    const response = await SignUp({ email: values.email })
    if (response.status === 200) {
      const { data } = response
      if (!data.userExisted) {
        removeCookie("kissHealthCookie")
        data.accessToken &&
          setCookie("kissHealthCookie", data.accessToken, { expires: 365 })
        SetLogin(true)
      } else {
        const cookie = getCookie("kissHealthCookie")
        if (cookie === undefined) {
          message.error("登錄連接已經發送到你的電子郵箱，請查看", 10)
        }
      }
    }
  }
  return (
    <Form
      onFinish={onFinish}
      style={{ maxWidth: "460px", width: "100%", padding: "0.5rem 0" }}
    >
      <Item
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "請輸入有效電郵",
          },
        ]}
      >
        <Input
          prefix={<MailFilled />}
          placeholder="電郵"
          autoComplete="off"
          addonAfter={<Button htmlType="submit">填寫簡單問卷</Button>}
        />
      </Item>
    </Form>
  )
}

LoginForm.propTypes = {
  beforeSubmit: propTypes.func,
}

LoginForm.defaultProps = {
  beforeSubmit: () => {},
}

export default LoginForm
