import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Statistic, CaretUpFilled, CaretDownFilled } from "../../lib/ui"
import SEO from "../seo"

const StatsWrapper = styled.div`
  background-color: ${props => props.theme.color.bgColor.whiteColor1};
  position: relative;
  padding: 1.2rem;
  border-radius: 0.5rem;
  align-items: center;
`

const TrendsWrapper = styled.div`
  display: inline-block;
  text-align: center;
  padding-right: 1.2rem;
  position: absolute;
  font-size: 1rem;
  right: 0;
  top: 1.5rem;
  color: ${props => {
    if (props.trends > 0) {
      return props.theme.color.fontColor.greenColor1
    } else if (props.trends === 0) {
      return props.theme.color.fontColor.grayColor3
    } else {
      return props.theme.color.fontColor.pinkColor3
    }
  }};
`

const OverallStats = ({ OverallValue, trends }) => (
  <StatsWrapper>
    <SEO title="本港數據" />
    <Statistic value={OverallValue} />
    <TrendsWrapper trends={trends}>
      <div>{Math.abs(trends) === 0 ? "─" : Math.abs(trends)}</div>
      <div>
        {Math.sign(trends) >= 0 ? (
          trends === 0 ? (
            ""
          ) : (
            <CaretUpFilled />
          )
        ) : (
          <CaretDownFilled />
        )}
      </div>
    </TrendsWrapper>
  </StatsWrapper>
)

OverallStats.propTypes = {
  OverallValue: PropTypes.number,
  trends: PropTypes.number,
}

OverallStats.defaultProps = {
  OverallValue: 0,
  trends: 0,
}

export default OverallStats
