import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import Menu from "./hamMenu"
import { Responsive, Col, Row } from "../../lib/ui"
import MaskMapIcon from "../../assets/images/maskmap.svg"
import KissHealth from "../../assets/images/kisshealth.svg"

const HeaderContainer = styled.header`
  background: ${props => props.theme.color.bgColor.pinkColor3};
  margin: 0;
  padding: 0.4rem 1.0875rem;
  min-height: 3rem;
  alignitems: center;
  @media (${props => props.theme.mediaQuery.mobile}) {
    padding: 0.4rem 1rem;
  }
`
const HeaderContent = styled(Row)``

const StyledLink = styled(Link)`
  color: ${props => props.theme.color.fontColor.whiteColor1};
  text-decoration: none;
  font-weight: bold;
`

const NavTrigger = styled.div`
  display: inline-block;
  text-align: right;
`

const Header = () => (
  <>
    <Responsive.Mobile>
      <HeaderContainer>
        <HeaderContent>
          <Col span={4} style={{ justifyContent: "flex-start" }}>
            <NavTrigger>
              <Menu />
            </NavTrigger>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
            }}
            span={16}
          >
            <StyledLink to="/">
              <MaskMapIcon style={{ width: "fit-content", height: "2rem" }} />
            </StyledLink>
          </Col>
        </HeaderContent>
      </HeaderContainer>
    </Responsive.Mobile>
    <Responsive.Tablet>
      <HeaderContainer>
        <HeaderContent>
          <Col
            style={{ display: "flex", justifyContent: "center" }}
            offset={8}
            span={8}
          >
            <StyledLink to="/">
              <MaskMapIcon style={{ width: "fit-content", height: "2rem" }} />
            </StyledLink>
          </Col>
          <Col offset={12} span={8} style={{ justifyContent: "flex-end" }}>
            <Menu />
          </Col>
        </HeaderContent>
      </HeaderContainer>
    </Responsive.Tablet>
    <Responsive.Desktop>
      <HeaderContainer>
        <HeaderContent>
          <Col span={8} style={{ justifyContent: "flex-start" }}>
            <NavTrigger>
              <Menu />
            </NavTrigger>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }} span={8}>
            <StyledLink to="/">
              <MaskMapIcon style={{ width: "fit-content", height: "2rem" }} />
            </StyledLink>
          </Col>

          <Col span={8} style={{ justifyContent: "flex-end" }}>
            <KissHealth
              style={{
                width: "fit-content",
                height: "2rem",
                textAlign: "right",
              }}
            />
          </Col>
        </HeaderContent>
      </HeaderContainer>
    </Responsive.Desktop>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
