import urlJoin from "url-join"
import request from "../../utils/request"
import { getCookie } from "../../utils/cookie"

const { GATSBY_API_URL: apiBaseUrl } = process.env

export const CheckSurvey = async () => {
  const url = urlJoin(apiBaseUrl, "users/profile")
  const token = getCookie("kissHealthCookie")
  const options = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  try {
    const response = await request.get(url, options)
    return response
  } catch (e) {
    if (e.response) {
      return e.response
    }
  }
}

export const PatchSurvey = async payload => {
  const url = urlJoin(apiBaseUrl, "survey")
  const token = getCookie("kissHealthCookie")
  const params = {
    ...payload,
  }
  const options = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  try {
    const response = await request.patch(url, params, options)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
}

export const SubmitNewSurvey = async payload => {
  const url = urlJoin(apiBaseUrl, "survey")
  const token = getCookie("kissHealthCookie")
  const params = {
    ...payload,
  }
  const options = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  try {
    const response = await request.post(url, params, options)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
}
