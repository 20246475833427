import React, { useState } from "react"
import styled from "styled-components"

const MenuIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 5;
  div {
    width: 1.5rem;
    height: 0.2rem;
    background: ${props => props.theme.color.bgColor.whiteColor1};
    border-radius: 5px;
    transform-origin: 1px;
    position: relation;
    transition: opacity 300ms, transform 300ms;

    :first-child {
      transform: ${props => (props.nav ? `rotate(40deg)` : `rotate(0)`)};
    }
    :nth-child(2) {
      opacity: ${props => (props.nav ? `0` : `1`)};
    }
    :nth-child(3) {
      transform: ${props => (props.nav ? `rotate(-40deg)` : `rotate(0)`)};
    }
`

const MenuLinks = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  height: 100vh;
  width: 50%;
  color: gray;
  background: ${props => props.theme.color.bgColor.pinkColor1};
  box-shadow: ${props =>
    props.nav ? "8px 0px 6px rgba(122,122,122,0.3)" : "none"};
  position: fixed;
  top: 3rem;
  left: 0;
  transition: transform 300ms;
  z-index: 5;
  transform: ${props => (props.nav ? "translateX(0)" : "translateX(-100%)")};
  ul {
    list-style-type: none;
  }
  li {
    margin-top: 1rem;
  }
  a {
    text-decoration: none;
    color: black;
    font-size: 1.5rem;

    :hover {
      color: pink;
    }
  }
`

const HamburgerMenu = () => {
  const [nav, showNav] = useState(false)
  return (
    <div>
      <MenuIcon nav={nav} onClick={() => showNav(!nav)}>
        <div />
        <div />
        <div />
      </MenuIcon>
      <MenuLinks nav={nav}>
        <ul>
          <li>
            <a href="/">首頁</a>
          </li>
          <li>
            <a href="/report/">口罩數據</a>
          </li>
          <li>
            <a href="/user/survey">問卷調查</a>
          </li>
        </ul>
      </MenuLinks>
    </div>
  )
}

export default HamburgerMenu
