import Footer from "./Footer"
import Header from "./Header"
import Layout from "./Layout/layout"
import LayoutWithImageBg from "./Layout/layoutWithBackground"
import Login from "./Login"
import Survey from "./Survey/survey"
import SecondSurvey from "./Survey/secondSurvey"
import SubmitSuccess from "./Survey/submit-success"
import Meta from "./meta"
import SEO from "./seo"
import PrivateRoute from "./PrivateRoute"
import Main from "./Main"
import OtpLogin from "./OtpLogin"
import { DistrictStats, OverallStats, Report } from "./Report"

export {
  Meta,
  SEO,
  Footer,
  Header,
  Layout,
  LayoutWithImageBg,
  Login,
  Main,
  Survey,
  SecondSurvey,
  SubmitSuccess,
  PrivateRoute,
  OtpLogin,
  DistrictStats,
  OverallStats,
  Report,
}
