import * as ThemeConstants from "./constants"

export const themeFactory = c => ({
  breakPoints: {
    mobile: {
      minWidth: c.MOBILE_MIN_WIDTH,
      maxWidth: c.MOBILE_MAX_WIDTH,
    },
    tablet: {
      minWidth: c.TABLET_MIN_WIDTH,
      maxWidth: c.TABLET_MAX_WIDTH,
    },
    desktop: {
      minWidth: c.DESKTOP_MIN_WIDTH,
      maxWidth: c.DESKTOP_MAX_WIDTH,
    },
  },
  mediaQuery: {
    mobile: `min-width: ${c.MOBILE_MIN_WIDTH}`,
    tablet: `min-width: ${c.TABLET_MIN_WIDTH}`,
    desktop: `min-width: ${c.DESKTOP_MIN_WIDTH}`,
  },
  fontSize: {
    xss: c.FONTSIZE_1,
    xs: c.FONTSIZE_2,
    sm: c.FONTSIZE_3,
    md: c.FONTSIZE_4,
    lg: c.FONTSIZE_5,
    xl: c.FONTSIZE_6,
    xxl: c.FONTSIZE_7,
    xxxl: c.FONTSIZE_8,
  },
  fontFamily: {
    base: c.FONT_FAMILY_1,
    logo: c.FONT_FAMILY_2,
    heading: c.FONT_FAMILY_2,
    button: c.FONT_FAMILY_2,
  },
  color: {
    bgColor: {
      grayColor1: c.GRAY_COLOR_1,
      grayColor2: c.GRAY_COLOR_2,
      grayColor3: c.GRAY_COLOR_3,
      grayColor4: c.GRAY_COLOR_4,
      blueColor1: c.BLUE_COLOR_1,
      whiteColor1: c.WHITE_COLOR_1,
      pinkColor1: c.PINK_COLOR_1,
      pinkColor2: c.PINK_COLOR_2,
      pinkColor3: c.PINK_COLOR_3,
      pinkColor4: c.PINK_COLOR_4,
      greenColor1: c.GREEN_COLOR_1,
    },
    fontColor: {
      grayColor1: c.GRAY_COLOR_1,
      grayColor2: c.GRAY_COLOR_2,
      grayColor3: c.GRAY_COLOR_3,
      grayColor4: c.GRAY_COLOR_4,
      blueColor1: c.BLUE_COLOR_1,
      whiteColor1: c.WHITE_COLOR_1,
      pinkColor1: c.PINK_COLOR_1,
      pinkColor2: c.PINK_COLOR_2,
      pinkColor3: c.PINK_COLOR_3,
      pinkColor4: c.PINK_COLOR_4,
      greenColor1: c.GREEN_COLOR_1,
    },
    iconColor: {
      grayColor1: c.GRAY_COLOR_1,
      grayColor2: c.GRAY_COLOR_2,
      grayColor3: c.GRAY_COLOR_3,
      grayColor4: c.GRAY_COLOR_4,
      blueColor1: c.BLUE_COLOR_1,
      whiteColor1: c.WHITE_COLOR_1,
      pinkColor1: c.PINK_COLOR_1,
      pinkColor2: c.PINK_COLOR_2,
      pinkColor3: c.PINK_COLOR_3,
      pinkColor4: c.PINK_COLOR_4,
      greenColor1: c.GREEN_COLOR_1,
    },
  },
})

export default themeFactory(ThemeConstants)
