import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hkmap.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          fluid={imageData}
          backgroundColor={`#040e18`}
          style={{
            height: "100%",
            minHeight: "870px",
            padding: "0px",
            margin: "0px",
            marginBottom: "-3px",
          }}
        >
          {children}
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 870px;
  height: 110vh;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: -3px;
`

export default StyledBackgroundSection
