/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import { Layout } from "antd"
import Theme from "../../theme/default"
import Meta from "../meta"
import Footer from "../Footer"
import Header from "../Header"
import "./layout.css"

const ContentContainer = styled.div`
  padding: 1.25rem 2rem;
  padding-bottom: 0;
`

const GeneralLayout = ({ children }) => {
  return (
    <ThemeProvider theme={Theme}>
      <>
        <Meta />
        <Layout
          style={{
            height: `100%`,
            background: "transparent",
          }}
        >
          <Header style={{ width: "100%", margin: 0 }} />
          <div
            style={{
              margin: "0 auto",
              marginBottom: "1.5rem",
              maxWidth: "1280px",
              width: `100%`,
            }}
          >
            <Layout.Content
              style={{
                height: "auto",
                background: "transparent",
                minHeight: "100vh",
              }}
            >
              <ContentContainer
                style={{ height: "auto", background: "transparent" }}
              >
                {children}
              </ContentContainer>
            </Layout.Content>
          </div>
          <Footer />
        </Layout>
      </>
    </ThemeProvider>
  )
}

GeneralLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GeneralLayout
