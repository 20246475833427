import { getCookie } from "./cookie"
import { CheckSurvey } from "../services"
const isBrowser = typeof window !== `undefined`

export const isLoggedIn = () => {
  if (!isBrowser) return false
  const cookie = getCookie("kissHealthCookie")
  return !!cookie
}

export const isSurveyCompleted = async () => {
  const response = await CheckSurvey()
  const { data } = response
  return data.surveyCompleted
}
