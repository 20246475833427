import urlJoin from "url-join"
import request from "../utils/request"

const { GATSBY_API_URL: apiBaseUrl } = process.env

const GetReport = async () => {
  const url = urlJoin(apiBaseUrl, "reports/latest")
  try {
    const response = await request.get(url)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
}

export { GetReport }
