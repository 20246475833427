import cookie from "js-cookie"

export const setCookie = (key, value, options = {}) => {
  cookie.set(key, value, {
    expires: 1,
    path: "/",
    ...options,
  })
}

export const removeCookie = key => {
  cookie.remove(key, {
    expires: 1,
  })
}

const getCookieFromBrowser = key => cookie.get(key)

export const getCookie = key => getCookieFromBrowser(key)
