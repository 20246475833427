import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { navigate } from "gatsby"
import { message } from "antd"
import { Spin, LoadingOutlined } from "../../lib/ui"
import { SignInWithOtp, CheckSurvey } from "../../services"
import { setCookie } from "../../utils/cookie"

const StyledSentence = styled.h3`
  color: ${props => props.theme.color.fontColor.whiteColor1};
  font-weight: bold;
  font-size: 2rem;
  line-height: 2rem;
  padding-left: 0.5rem;
  margin-top: 1rem;
  text-align: center;
  height: auto;
  @media (${props => props.theme.mediaQuery.mobile}) {
    font-size: 1.2rem;
  }
`
const OptLogin = ({ otp }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const CheckSurveyStatus = async () => {
      const response = await CheckSurvey()
      if (response.status === 200) {
        console.log(response)
        return response.data.surveyCompleted
      }
      return false
    }
    async function SignIn() {
      const data = { otp }
      const response = await SignInWithOtp(data)
      console.log(response)
      if (response.status === 200) {
        setLoading(false)
        const { data } = response
        setCookie("kissHealthCookie", data.accessToken, { expires: 365 })
        setLoading(false)
        const SurveyCompleted = await CheckSurveyStatus()
        if (SurveyCompleted) {
          //navigate("/user/second-survey")
          typeof window !== "undefined" &&
            window.location.assign("/user/second-survey")

        } else {
          //navigate("/user/survey")
          typeof window !== "undefined" &&
            window.location.assign("/user/survey")
        }
      } else {
        message.error("認證已經過期，請返回首頁重新登錄", 5)
      }
    }
    setTimeout(SignIn, 5000)
  }, [])

  return (
    <>
      <Spin indicator={<LoadingOutlined />} />
      <StyledSentence>跳轉至其他頁面...</StyledSentence>
    </>
  )
}

OptLogin.propTypes = {
  otp: PropTypes.string,
}

export default OptLogin
