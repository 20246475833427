import urlJoin from "url-join"
import request from "../utils/request"
import { getCookie } from "../utils/cookie"

const { GATSBY_API_URL: apiBaseUrl } = process.env

export const Subscription = async payload => {
  const url = urlJoin(apiBaseUrl, "subscriptions")
  const token = getCookie("kissHealthCookie")
  const params = {
    ...payload,
  }

  const options = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  try {
    const response = await request.post(url, params, options)

    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
}
