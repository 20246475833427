import React from "react"
import { message } from "antd"
import PropTypes from "prop-types"
import queryString from "query-string"
import styled from "styled-components"
import { Location } from "@reach/router"
import { Responsive, Form, Row, Checkbox } from "../../lib/ui"
import { Login, LayoutWithImageBg, OtpLogin } from "../../components"
import SloganIcon from "../../assets/images/slogan.svg"

const StyledLink = styled.a`
  color: ${props => props.theme.color.fontColor.whiteColor1};
  cursor: pointer;
`

const StyledSentence = styled.div`
  color: ${props => props.theme.color.fontColor.whiteColor1};
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
  height: auto;
  @media (${props => props.theme.mediaQuery.mobile}) {
    font-size: 1rem;
  }
`

const Main = ({ location }) => {
  const formRef = React.createRef()
  const { otp } = queryString.parse(location.search)

  console.log(otp)
  const CheckBoxHandlerGenerator = name => e => {
    formRef.current.setFieldsValue({
      [name]: e.target.checked,
    })
  }

  const CheckBeforeLogin = () => {
    const formData = formRef.current.getFieldValue()
    if (!formData.privacy) {
      message.error("請閱讀隱私政策", 3)
      return false
    }
    if (!formData.budisclaimer) {
      message.error("請閱讀免責協議", 3)
      return false
    }
    return true
  }

  return (
    <LayoutWithImageBg style={{ background: "transparent" }}>
      {otp ? (
        <OtpLogin otp={otp} />
      ) : (
        <>
          <Responsive.Mobile>
            <SloganIcon style={{ width: "auto", height: "6.7rem" }} />
            <StyledSentence style={{ marginTop: "0rem" }}>
              康復香港，全民使命。
            </StyledSentence>
            <StyledSentence>
              SundayKiss特設「互助互罩地圖」*
              透視各區居民家中的口罩用量，增加資訊透明度。
              希望把資訊帶給區議員或相關人士,亦鼓勵大家伸出援手，社區互助，齊心抗疫！
            </StyledSentence>
            <StyledSentence>*地圖將於第一期資料蒐集後更新</StyledSentence>
            <StyledSentence>互助互罩，香港加油。</StyledSentence>
            <StyledSentence>
              請輸入有效電郵以作登記:
              <Login beforeSubmit={CheckBeforeLogin} />
            </StyledSentence>
            <Form
              style={{ paddingLeft: "0.5rem", paddingTop: "0px" }}
              ref={formRef}
            >
              <Form.Item>
                <Row>
                  <Checkbox onChange={CheckBoxHandlerGenerator("privacy")}>
                    本人同意遵照
                    <a href="https://www.nmg.com.hk/privacy/" target="__blank">
                      該私隱政策
                    </a>
                    來處理個人資料。
                  </Checkbox>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row>
                  <Checkbox onChange={CheckBoxHandlerGenerator("budisclaimer")}>
                    本人已詳閱及理解
                    <a href="http://www.nmg.com.hk/budisclaimer/">免責聲明</a>
                    來處理個人資料。
                  </Checkbox>
                </Row>
              </Form.Item>
            </Form>
            <StyledSentence>
              如有口罩想捐助予有需要人士，請電郵至:
              <StyledLink href="mailto: mask@nmg.com.hk">
                mask@nmg.com.hk
              </StyledLink>
            </StyledSentence>
          </Responsive.Mobile>
          <Responsive.Tablet>
            <SloganIcon style={{ width: "fit-content", height: "10rem" }} />
            <StyledSentence style={{ marginTop: "0rem" }}>
              康復香港，全民使命。
            </StyledSentence>
            <StyledSentence>
              SundayKiss特設「互助互罩地圖」*
              透視各區居民家中的口罩用量，增加資訊透明度。
              希望把資訊帶給區議員或相關人士,亦鼓勵大家伸出援手，社區互助，齊心抗疫！
            </StyledSentence>
            <StyledSentence>*地圖將於第一期資料蒐集後更新</StyledSentence>
            <StyledSentence>互助互罩，香港加油。</StyledSentence>
            <StyledSentence>
              請輸入有效電郵以作登記:
              <Login beforeSubmit={CheckBeforeLogin} />
            </StyledSentence>
            <Form
              style={{ paddingLeft: "0.5rem", paddingTop: "0px" }}
              ref={formRef}
            >
              <Form.Item>
                <Row>
                  <Checkbox onChange={CheckBoxHandlerGenerator("privacy")}>
                    本人同意遵照
                    <a href="https://www.nmg.com.hk/privacy/" target="__blank">
                      該私隱政策
                    </a>
                    來處理個人資料。
                  </Checkbox>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row>
                  <Checkbox onChange={CheckBoxHandlerGenerator("budisclaimer")}>
                    本人已詳閱及理解
                    <a href="http://www.nmg.com.hk/budisclaimer/">免責聲明</a>
                    來處理個人資料。
                  </Checkbox>
                </Row>
              </Form.Item>
            </Form>
            <StyledSentence>
              如有口罩想捐助予有需要人士，請電郵至:
              <StyledLink href="mailto: mask@nmg.com.hk">
                mask@nmg.com.hk
              </StyledLink>
            </StyledSentence>
          </Responsive.Tablet>
          <Responsive.Desktop style={{ alignItems: "center" }}>
            <SloganIcon style={{ width: "fit-content", height: "10rem" }} />
            <StyledSentence style={{ marginTop: "0rem" }}>
              康復香港，全民使命。
            </StyledSentence>
            <StyledSentence>
              SundayKiss特設「互助互罩地圖」*
              透視各區居民家中的口罩用量，增加資訊透明度。
              <br></br>
              希望把資訊帶給區議員或相關人士,亦鼓勵大家伸出援手，社區互助，齊心抗疫！
            </StyledSentence>
            <StyledSentence>*地圖將於第一期資料蒐集後更新</StyledSentence>
            <StyledSentence>互助互罩，香港加油。</StyledSentence>
            <StyledSentence>
              請輸入有效電郵以作登記:
              <Login beforeSubmit={CheckBeforeLogin} />
            </StyledSentence>
            <Form
              style={{ paddingLeft: "0.5rem", paddingTop: "0px" }}
              ref={formRef}
            >
              <Form.Item>
                <Row>
                  <Checkbox onChange={CheckBoxHandlerGenerator("privacy")}>
                    本人同意遵照
                    <a href="https://www.nmg.com.hk/privacy/" target="__blank">
                      該私隱政策
                    </a>
                    來處理個人資料。
                  </Checkbox>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row>
                  <Checkbox onChange={CheckBoxHandlerGenerator("budisclaimer")}>
                    本人已詳閱及理解
                    <a href="http://www.nmg.com.hk/budisclaimer/">免責聲明</a>
                    來處理個人資料。
                  </Checkbox>
                </Row>
              </Form.Item>
            </Form>
            <StyledSentence>
              如有口罩想捐助予有需要人士，請電郵至:
              <StyledLink href="mailto: mask@nmg.com.hk">
                mask@nmg.com.hk
              </StyledLink>
            </StyledSentence>
          </Responsive.Desktop>
        </>
      )}
    </LayoutWithImageBg>
  )
}

Main.propTypes = {
  location: PropTypes.object,
}

const MainWithLocation = () => <Location>{Main}</Location>
export default MainWithLocation
