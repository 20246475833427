import React from "react"
import Helmet from "react-helmet"

const Meta = () => (
  <Helmet>
    <link
      href="https://fonts.googleapis.com/css?family=Rubik:300,400,500,700"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Karla:300,400,500,700"
      rel="stylesheet"
    />
  </Helmet>
)

export default Meta


