import { Col as AntDCol } from "antd"
import styled from "styled-components"

const Col = styled(AntDCol)`
  display: flex;
  font-weight: normal;
  align-items: center;
`

export default Col
