import { Statistic as AntDStatistic } from "antd"
import styled from "styled-components"

const Statistic = styled(AntDStatistic)`
  background: ${props => props.theme.color.bgColor.whiteColor1};
  display: ${props => (props.isDistrict ? "block" : "inline-block")};
  div.ant-statistic-title {
    text-align: center;
    color: ${props => props.theme.color.fontColor.pinkColor4};

    font-size: ${props => (props.isDistrict ? "1rem" : "2rem")};
  }
  div.ant-statistic-content {
    text-align: center;
    font-weight: bold;
    font-size: ${props => (props.isDistrict ? "1rem" : "2rem")};
  }
`

export { Statistic }
