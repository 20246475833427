import React from "react"
import PropTypes from "prop-types"
import { Layout } from "antd"
import styled, { ThemeProvider } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../seo"
import ImageBackground from "../ImageBackground"
import Theme from "../../theme/default"
import Meta from "../meta"
import Footer from "../Footer"
import Header from "../Header"
import "./layout.css"

const { Content } = Layout

const StyledContent = styled(Content)`
  margin: 0 auto;
  display: block;
  max-width: 1280px;
  width: 100%;
  height: fit-content !important;
  padding: 1.25rem 2rem;
`

const LayoutWithImageBg = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <ThemeProvider theme={Theme}>
      <ImageBackground>
        <SEO title={data.site.siteMetadata.title} />
        <Meta />
        <Layout
          style={{
            height: "100%",
            background: "transparent",
          }}
        >
          <Header style={{ width: "100%", margin: 0 }} />
          <StyledContent>{children}</StyledContent>
          <Footer />
        </Layout>
      </ImageBackground>
    </ThemeProvider>
  )
}
LayoutWithImageBg.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutWithImageBg
