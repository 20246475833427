import { Menu as AntDMenu } from "antd"
import styled from "styled-components"

const { SubMenu: AntDSubMenu, Item: AntDItem } = AntDMenu

const Menu = styled(AntDMenu)`
  border-bottom: 0px;
  li.ant-menu-item {
    border: 0px;
  }
  li.ant-menu-item:hover {
    border: 0px;
  }
  li.ant-menu-item-active {
    color: ${props => props.theme.color.fontColor.pinkColor1};
    border: 0px;
  }
  .ant-menu-horizontal {
    height: 2rem;
  }
  .ant-menu-submenu-title {
    padding: 0px;
    text-align: right;
    .anticon {
      margin: 0;
    }
  }

  li.ant-menu-submenu-horizontal {
    border: 0px solid transparent;
    margin: 0px;
  }
  li.ant-menu-submenu-horizontal:active {
    border: 0px solid transparent;
    margin: 0px;
  }
  li.ant-menu-submenu-horizontal:hover {
    margin: 0px;
  }

  li.ant-menu-submenu-horizontal:hover {
    border: 0px solid transparent;
    margin-top: 0px;
  }
`
const SubMenu = styled(AntDSubMenu)``
const Item = styled(AntDItem)`
  color: pink;
  font-weight: bold;
  &:hover {
    color: pink;
  }
`

export { Menu, SubMenu, Item }
