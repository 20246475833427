import urlJoin from "url-join"
import request from "../utils/request"

const { GATSBY_API_URL: apiBaseUrl } = process.env

const ListRegion = async () => {
  const url = urlJoin(apiBaseUrl, "regions")
  try {
    const response = await request.get(url)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
}
const ListDistrict = async regionRefId => {
  const url = urlJoin(
    apiBaseUrl,
    "postdistricts",
    `?regionRefId=${regionRefId}`
  )

  try {
    const response = await request.get(url)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
}

const ListEstate = async streetRefId => {
  const url = urlJoin(
    apiBaseUrl,
    "estates",
    `?postDistrictRefId=${streetRefId}`
  )
  try {
    const response = await request.get(url)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
}
const ListBuilding = async estateRefId => {
  const url = urlJoin(apiBaseUrl, "buildings", `?estateRefId=${estateRefId}`)
  try {
    const response = await request.get(url)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
}

const QueryAddress = async location => {
  const url = urlJoin(apiBaseUrl, "address/search", `?q=${location}`)
  try {
    const response = await request.get(url)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
}

export { ListRegion, ListDistrict, ListEstate, ListBuilding, QueryAddress }
