import React from "react"
import { message } from "antd"
import styled from "styled-components"
import SEO from "../seo"
import { Row, Col as AntDCol } from "../../lib/ui"
import SubmitSuccess from "../../assets/images/submitSuccess.svg"
import ShareToFB from "../../assets/images/facebook.svg"
import ShareToWhatsApp from "../../assets/images/whatsapp.svg"
import CopyTo from "../../assets/images/copyto.svg"

const StyledHeader = styled.h4`
  color: ${props => props.theme.color.fontColor.pinkColor3};
  text-align: center;
  margin-top: 2rem;
`

const Col = styled(AntDCol)`
  justify-content: center;
`

const ShareHeader = styled.h4`
  background-color: ${props => props.theme.color.fontColor.pinkColor3};
  color: ${props => props.theme.color.fontColor.whiteColor1};
  width: 60%;
  margin: 0 auto;
  display: block;
  padding: 0.8rem 0.6rem;
  line-height: 1rem;
  border-radius: 1rem;
`
const ShareHeaderContainer = styled.div`
  position: absolute;
  top: -1.3rem;
  text-align: center;
  width: 100%;
`

const ShareToFriends = styled.div`
  background-color: ${props => props.theme.color.fontColor.whiteColor1};
  color: ${props => props.theme.color.fontColor.pinkColor3};
  border-radius: 1rem;
  padding: 3rem 0;
  position: relative;
`
const ShareText = styled.div`
  color: ${props => props.theme.color.fontColor.pinkColor3};
`

const SubmitSuccessPage = () => {
  if (typeof window !== "undefined") {
    window.ClipBoard = (function(window, document, navigator) {
      var textArea, copy

      function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i)
      }

      function createTextArea(text) {
        textArea = document.createElement("textArea")
        textArea.value = text
        document.body.appendChild(textArea)
      }

      function selectText() {
        var range, selection

        if (isOS()) {
          range = document.createRange()
          range.selectNodeContents(textArea)
          selection = window.getSelection()
          selection.removeAllRanges()
          selection.addRange(range)
          textArea.setSelectionRange(0, 999999)
        } else {
          textArea.select()
        }
      }

      function copyToClipboard() {
        document.execCommand("copy")
        document.body.removeChild(textArea)
      }

      copy = function(text) {
        createTextArea(text)
        selectText()
        copyToClipboard()
      }

      return {
        copy: copy,
      }
    })(window, document, navigator)
  }

  const copyLinkToClipBoard = () => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText("https://www.sundaykisshealth.com/")
        .then(() => {
          message.success("成功複製")
        })
    } else {
      window.ClipBoard.copy("https://www.sundaykisshealth.com/")
      message.success("成功複製")
    }
  }

  return (
    <div style={{ minHeight: "100vh" }}>
      <SEO title="成功提交" />
      <SubmitSuccess
        style={{
          width: "auto",
          height: "8rem",
          display: "block",
          margin: "0 auto",
        }}
      />
      <StyledHeader>感謝你完成以上調查！</StyledHeader>
      <StyledHeader>
        本調查會以持續型式進行，為保持數據更新度，我們將邀請你每星期重新登入更新資料。
      </StyledHeader>
      <StyledHeader>
        抗疫特集 一
        <a
          href="https://www.sundaykiss.com/tag/%E6%8A%97%E7%96%AB%E7%89%B9%E9%9B%86/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            cursor: "pointer",
          }}
        >
          <StyledHeader
            style={{ textDecoration: "underline", display: "inline" }}
          >
            網碌盡最新最齊資訊
          </StyledHeader>
        </a>
      </StyledHeader>
      <StyledHeader>
        <ShareToFriends>
          <ShareHeaderContainer>
            <ShareHeader>邀請朋友參加調查</ShareHeader>
          </ShareHeaderContainer>
          <Row>
            <Col span={8}>
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=https://www.sundaykisshealth.com/"
                target="__blank"
              >
                <ShareToFB
                  style={{
                    width: "auto",
                    height: "3.5rem",
                    marginBottom: ".25rem",
                  }}
                />
                <ShareText style={{ margin: "0px" }}>facebook</ShareText>
              </a>
            </Col>
            <Col span={8}>
              <a
                href="whatsapp://send?text=https://www.sundaykisshealth.com/"
                data-action="share/whatsapp/share"
              >
                <ShareToWhatsApp
                  style={{
                    width: "auto",
                    height: "3.5rem",
                    marginBottom: ".25rem",
                  }}
                />
                <ShareText style={{ margin: "0px" }}>whatsapp</ShareText>
              </a>
            </Col>
            <Col span={8}>
              <div
                data-clipboard-action="copy"
                data-clipboard-text="https://www.sundaykisshealth.com/"
                onClick={copyLinkToClipBoard}
                style={{ cursor: "pointer" }}
              >
                <CopyTo
                  style={{
                    width: "auto",
                    height: "3.5rem",
                    marginBottom: ".25rem",
                  }}
                />
                <ShareText style={{ margin: "0px", display: "block" }}>
                  複製連結
                </ShareText>
              </div>
            </Col>
          </Row>
        </ShareToFriends>
      </StyledHeader>
      <StyledHeader>
        <a
          href="/report"
          style={{
            cursor: "pointer",
          }}
        >
          <StyledHeader
            style={{ textDecoration: "underline", display: "inline" }}
          >
            查看全港口罩及相關數據
          </StyledHeader>
        </a>
      </StyledHeader>
    </div>
  )
}

export default SubmitSuccessPage
