import React from "react"
import BreakPoint from "./BreakPoint"

export const DesktopBreakPoint = props => (
  <BreakPoint {...props} name="desktop" />
)

export const TabletBreakPoint = props => <BreakPoint {...props} name="tablet" />

export const MobileBreakPoint = props => <BreakPoint {...props} name="mobile" />

export default {
  Desktop: DesktopBreakPoint,
  Tablet: TabletBreakPoint,
  Mobile: MobileBreakPoint,
}
