import { Spin as AntDSpin } from "antd"
import styled from "styled-components"

const Spin = styled(AntDSpin)`
  display: block;
  text-align: center;
  min-width: 4rem;
  margin: 2rem auto;
`

export { Spin }
