import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import SEO from "../seo"
import { Button, message, notification } from "antd"
import { InputNumber, Form, Row, Col } from "../../lib/ui"
import { PatchSurvey } from "../../services"

const StyledRow = styled(Row)`
  font-weight: bold;
`
const StyledCol = styled(Col)`
  display: flex;
`
const StyledButton = styled(Button)`
  color: ${props => props.theme.color.fontColor.pinkColor3};
`

const SecondSurvey = () => {
  const formRef = React.createRef()
  const submitSurvey = async () => {
    const formData = formRef.current.getFieldValue()

    const data = { ...formData }
    const response = await PatchSurvey(data)
    if (response.status === 200) {
      //navigate("/user/submit-success")
      typeof window !== "undefined" &&
        window.location.assign("/user/submit-success")
    } else {
      notification.error({
        message: response.statusText,
        description: JSON.stringify(response.data),
        duration: null,
      })
    }
  }
  const checkSubmitData = () => {
    const surveyData = formRef.current.getFieldValue()
    if (!surveyData.maskBalance) {
      message.error("請輸入口罩存量 (Q2)", 3)
      return false
    }
    if (!surveyData.maskDailyConsumptionAmount) {
      message.error("請輸入家中每日消耗口罩數量 (Q3)", 3)
      return false
    }
    return true
  }
  const checkAndSubmit = () => {
    if (checkSubmitData()) {
      submitSurvey()
    }
  }
  const HandlerGenerator = name => value => {
    formRef.current.setFieldsValue({
      [name]: value,
    })
  }
  return (
    <>
      <SEO title="問卷調查" />
      <Form
        onFinish={checkAndSubmit}
        ref={formRef}
        style={{ minHeight: "100vh" }}
      >
        <StyledRow>*Q1. 請問你在家中的口罩存量還有多少？</StyledRow>
        <Form.Item
          name="maskBalance"
          rules={[
            {
              required: true,
              message: "請輸入口罩存量",
            },
          ]}
        >
          <InputNumber
            type="number"
            min={0}
            onChange={HandlerGenerator("maskBalance")}
          />
        </Form.Item>
        <StyledRow>*Q2. 請問你家中每天需要消耗多少口罩？</StyledRow>
        <Form.Item
          name="maskDailyConsumptionAmount"
          rules={[
            {
              required: true,
              message: "請輸入家中每日消耗口罩數量",
            },
          ]}
        >
          <InputNumber
            type="number"
            min={0}
            onChange={HandlerGenerator("maskDailyConsumptionAmount")}
          />
        </Form.Item>
        <Form.Item>
          <StyledRow>
            <StyledCol
              style={{ justifyContent: "flex-end" }}
              offset={22}
              span={2}
            >
              <StyledButton htmlType="submit">提交</StyledButton>
            </StyledCol>
          </StyledRow>
        </Form.Item>
      </Form>
    </>
  )
}

export default SecondSurvey
