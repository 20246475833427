/* responsive */
export const MOBILE_MIN_WIDTH = "0px"
export const MOBILE_MAX_WIDTH = "425px"
export const TABLET_MIN_WIDTH = "426px"
export const TABLET_MAX_WIDTH = "767px"
export const DESKTOP_MIN_WIDTH = "768px"
export const DESKTOP_MAX_WIDTH = undefined

/* font size */

export const FONTSIZE_1 = "10px"
export const FONTSIZE_2 = "12px"
export const FONTSIZE_3 = "14px"
export const FONTSIZE_4 = "16px"
export const FONTSIZE_5 = "18px"
export const FONTSIZE_6 = "24px"
export const FONTSIZE_7 = "28px"
export const FONTSIZE_8 = "32px"
export const FONTSIZE_9 = "36px"

/* font family */

export const FONT_FAMILY_1 = "'Nunito Sans', sans-serif"
export const FONT_FAMILY_2 = "'Montserrat', sans-serif"

/* color */
export const GRAY_COLOR_1 = "#DDDDDD"
export const GRAY_COLOR_2 = "#F8F8F8"
export const GRAY_COLOR_3 = "#555555"
export const GRAY_COLOR_4 = "#999999"
export const BLUE_COLOR_1 = "#1D7CDC"
export const WHITE_COLOR_1 = "#FFFFFF"
export const PINK_COLOR_1 = "#F09AA2"
export const PINK_COLOR_2 = "#FBE1E5"
export const PINK_COLOR_3 = "#E4466E"
export const PINK_COLOR_4 = "#C97480"
export const GREEN_COLOR_1 = "#49985C"
