import axios from "axios"

const request = {
  request: axios.request,
  get: axios.get,
  post: axios.post,
  patch: axios.patch,
}

export default request
