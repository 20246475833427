import urlJoin from "url-join"
import request from "../utils/request"

const { GATSBY_API_URL: apiBaseUrl } = process.env

export const SignInWithOtp = async payload => {
  const url = urlJoin(apiBaseUrl, "auth/otp")
  const params = {
    ...payload,
  }
  try {
    const response = await request.post(url, params)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
}
