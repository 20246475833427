import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import {
  Button,
  Checkbox,
  Radio,
  message,
  notification,
  AutoComplete,
} from "antd"
import { Form, InputNumber, Select, Input, Row, Col } from "../../lib/ui"
import {
  ListRegion,
  ListDistrict,
  ListEstate,
  ListBuilding,
  SubmitNewSurvey,
  Subscription,
  QueryAddress,
} from "../../services"
import SEO from "../seo"
import { debounce } from "../../utils/debounce"

const Page = styled.div`
  display: ${props => props.page !== props.currentPage && "none"};
  max-width: 1280px;
  display: block;
  margin: 0 auto;
`
const OptionText = styled.div`
  color: ${props => props.theme.color.fontColor.pinkColor3} !important;
`
const StyledButton = styled(Button)`
  color: ${props => props.theme.color.fontColor.pinkColor3};
`
const StyledCheckbox = styled(Checkbox)`
  color: ${props => props.theme.color.fontColor.pinkColor4} !important;
`
const { Option } = Select

const SurveyPage = () => {
  const [page, setPage] = useState(1)
  const [regionRefId, setregionRefId] = useState("hong-kong")
  const [enableDistrict, setEnableDistrict] = useState(true)
  const [districtRefId, setdistrictRefId] = useState("aberdeen")
  const [enableEstate, setEnableEstate] = useState(true)
  const [estateRefId, setestateRefId] = useState("jing-hui-garden")
  const [enableBuilding, setEnableBuilding] = useState(true)
  const [regionList, setRegionList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [estateList, setEstateList] = useState([])
  const [buildingList, setBuildingList] = useState([])
  const [autoCompleteList, setAutoCompleteList] = useState([])
  const [autoCompleteValue, setAutoCompleteValue] = useState("")
  const [selectAutoCompOpt, setSelectAutoCompOpt] = useState({})
  const autoCompOptions = autoCompleteList.map(location => (
    <Option key={location.id} value={location}>
      {location.fullAddress}
    </Option>
  ))
  const formRef = React.createRef()
  const onAutoCompSearch = async searchText => {
    if (searchText !== "") {
      const response = await QueryAddress(searchText)
      if (response.status === 200) {
        const { data } = response

        const OptionList = data.map(location => ({
          ...location,
          value: location.fullAddress,
        }))
        console.log(OptionList)
        setAutoCompleteList(OptionList)
      }
    }
  }

  const onAutoCompChange = searchText => {
    console.log(searchText)
    setAutoCompleteValue(searchText)
  }

  const onAutoCompSelect = (data, option) => {
    setSelectAutoCompOpt(option)
  }

  useEffect(() => {
    async function fetchData() {
      const regionRes = await ListRegion()
      setRegionList(regionRes.data)
    }
    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      const response = await ListDistrict(regionRefId)
      setDistrictList(response.data)
    }
    fetchData()
    return () => {}
  }, [regionRefId])

  useEffect(() => {
    async function fetchData() {
      const response = await ListEstate(districtRefId)
      setEstateList(response.data)
    }
    fetchData()
    return () => {}
  }, [districtRefId])

  useEffect(() => {
    async function fetchData() {
      const response = await ListBuilding(estateRefId)
      setBuildingList(response.data)
    }
    fetchData()
    return () => {}
  }, [estateRefId])

  const checkAndSubmit = async () => {
    const nextPage = page + 1
    if (page === 1 && CheckFirstPageRequiredData()) {
      const validatedResult = formRef.current.getFieldValue()
      const data = {
        fullName: validatedResult.fullName,
        gender: validatedResult.gender,
        yearOfBirth: validatedResult.yearOfBirth,
        postDistrictRefId: validatedResult.postDistrictRefId,
        estateRefId: validatedResult.estateRefId,
        buildingRefId: validatedResult.buildingRefId,
        otherAddress: validatedResult.otherAddress,
      }
      const response = await SubmitNewSurvey(data)

      if (response.status === 200) {
        setPage(nextPage)
      } else {
        notification.error({
          message: response.statusText,
          description: JSON.stringify(response.data),
          duration: null,
        })
      }
    }

    if (page === 2 && CheckSecondPageRequiredData()) {
      const validatedResult = formRef.current.getFieldValue()
      const data = {
        familySize: validatedResult.familySize,
        maskBalance: validatedResult.maskBalance,
        maskDailyConsumptionAmount: validatedResult.maskDailyConsumptionAmount,
        priceOfMask: validatedResult.priceOfMask,
        idealPriceOfMask: validatedResult.idealPriceOfMask,
      }
      const response = await SubmitNewSurvey(data)

      if (response.status === 200) {
        setPage(nextPage)
      } else {
        notification.error({
          message: response.statusText,
          description: JSON.stringify(response.data),
          duration: null,
        })
      }
    }
    if (page === 3 && CheckThirdPageRequiredData()) {
      const validatedResult = formRef.current.getFieldValue()
      const subscriptionData = {
        subscribeHealthUpdate: !!validatedResult.subscribeHealthUpdate,
        subscribePromotion: !!validatedResult.subscribePromotion,
      }
      const data = {
        numOfElders: validatedResult.numOfElders
          ? validatedResult.numOfElders
          : 0,
        numOfChronicIllnesses: validatedResult.numOfChronicIllnesses
          ? validatedResult.numOfChronicIllnesses
          : 0,
        weeksOfPregnancy: validatedResult.weeksOfPregnancy
          ? validatedResult.weeksOfPregnancy
          : 0,
        numOfKids: validatedResult.numOfKids,
        agesOfKids: validatedResult.agesOfKids,
        doctorInFamily: !!validatedResult.doctorInFamily,
        nurseInFamily: !!validatedResult.nurseInFamily,
        pharmacistInFamily: !!validatedResult.pharmacistInFamily,
        midwifeInFamily: !!validatedResult.midwifeInFamily,
        occupationalTherapistInFamily: !!validatedResult.occupationalTherapistInFamily,
        physicalTherapistInFamily: !!validatedResult.physicalTherapistInFamily,
        medicalCareGiverInFamily: !!validatedResult.medicalCareGiverInFamily,
        ambulanceInFamily: !!validatedResult.ambulanceInFamily,
        radiologistInFamily: !!validatedResult.radiologistInFamily,
        elderlyCareGiverInFamily: !!validatedResult.elderlyCareGiverInFamily,
        otherInFamily: validatedResult.otherInFamily,
        numOfHospitalVisit: validatedResult.numOfHospitalVisit
          ? validatedResult.numOfHospitalVisit
          : 0,
        needMaskDonation: validatedResult.needMaskDonation
          ? validatedResult.needMaskDonation
          : 0,
        isComplete: true,
      }
      const response = await SubmitNewSurvey(data)
      if (response.status === 200) {
        // navigate("/user/submit-success")
        typeof window !== "undefined" &&
          window.location.assign("/user/submit-success")
        await Subscription(subscriptionData)
      } else {
        notification.error({
          message: response.statusText,
          description: JSON.stringify(response.data),
          duration: null,
        })
      }
    }
  }

  const CheckFirstPageRequiredData = () => {
    console.log("check First Page")
    const validatedResult = formRef.current.getFieldValue()
    console.log(validatedResult)
    if (validatedResult.gender === undefined) {
      message.error("請選擇性別", 3)
      return false
    }
    if (validatedResult.yearOfBirth === undefined) {
      message.error("請輸入出生年份", 3)
      return false
    }
    if (validatedResult.regionRefId === undefined) {
      message.error("請選擇地域", 3)
      return false
    }

    if (validatedResult.postDistrictRefId === undefined) {
      message.error("請選擇地區", 3)
      return false
    }

    if (
      !validatedResult.otherAddress &&
      validatedResult.estateRefId === undefined
    ) {
      message.error("請選擇屋邨", 3)
      return false
    }

    return true
  }

  const CheckSecondPageRequiredData = () => {
    const validatedResult = formRef.current.getFieldValue()
    console.log(validatedResult)
    if (validatedResult.familySize === undefined) {
      message.error("請填寫家庭成員數量 (Q1)", 3)
      return false
    }
    if (validatedResult.maskBalance === undefined) {
      message.error("請輸入口罩存量 (Q2)", 3)
      return false
    }
    if (validatedResult.maskDailyConsumptionAmount === undefined) {
      message.error("請輸入家中每日消耗口罩數量 (Q3)", 3)
      return false
    }
    if (validatedResult.priceOfMask === undefined) {
      message.error("請輸入購買口罩的價格 (Q4)", 3)
      return false
    }
    if (validatedResult.idealPriceOfMask === undefined) {
      message.error("請輸入願意接受的口罩價格 (Q5)", 3)
      return false
    }
    return true
  }

  const CheckThirdPageRequiredData = () => {
    const validatedResult = formRef.current.getFieldValue()
    console.log(validatedResult)
    if (
      validatedResult.elders === undefined &&
      validatedResult.illness === undefined &&
      validatedResult.noEldersOrIllness === undefined
    ) {
      message.error("請確認家中有無需要特別照顧的人士 (Q6)", 3)
      return false
    }
    if (validatedResult.elders && validatedResult.numOfElders === undefined) {
      message.error("請輸入長者數量 (Q6.1)", 3)
      return false
    }
    if (
      validatedResult.illness &&
      validatedResult.numOfChronicIllnesses === undefined
    ) {
      message.error("請輸入長期病患數量 (Q6.2)", 3)
      return false
    }

    if (validatedResult.pregancy === undefined) {
      message.error("請確定家中有沒有孕婦 (Q7)", 3)
      return false
    }

    if (
      validatedResult.pregancy &&
      validatedResult.weeksOfPregnancy === undefined
    ) {
      message.error("請輸入孕婦懷孕週數 (Q7.1)", 3)
      return false
    }

    if (validatedResult.numOfKids === undefined) {
      message.error("請確定家中小童數量 (Q8)", 3)
      return false
    }

    if (
      validatedResult.agesOfKids === undefined &&
      validatedResult.numOfKids !== 0
    ) {
      message.error("請輸入所有小童年齡 (Q8)", 3)
      return false
    }

    if (
      validatedResult.numOfKids !== 0 &&
      validatedResult.numOfKids !== validatedResult.agesOfKids.length
    ) {
      message.error("請輸入所有小童年齡 (Q8)", 3)
      return false
    }

    if (validatedResult.medicalStaffInFamily === undefined) {
      message.error("請確定家中有沒有醫護人員 (Q9)", 3)
      return false
    }
    if (
      validatedResult.medicalStaffInFamily &&
      validatedResult.doctorInFamily === undefined &&
      validatedResult.nurseInFamily === undefined &&
      validatedResult.midwifeInFamily === undefined &&
      validatedResult.pharmacistInFamily === undefined &&
      validatedResult.occupationalTherapistInFamily === undefined &&
      validatedResult.physicalTherapistInFamily === undefined &&
      validatedResult.medicalCareGiverInFamily === undefined &&
      validatedResult.ambulanceInFamily === undefined &&
      validatedResult.radiologistInFamily === undefined &&
      validatedResult.elderlyCareGiverInFamily === undefined
    ) {
      message.error("請選擇醫護人員擔任角色 (Q9.1)", 3)
      return false
    }

    if (validatedResult.haveOthers && !validatedResult.otherInFamily) {
      message.error("請輸入醫護人員擔任角色(Q9.1)", 3)
      return false
    }
    return true
  }

  const HandlerGenerator = name => {
    if (name === "regionRefId") {
      return value => {
        setregionRefId(value)
        setEnableDistrict(false)
        formRef.current.setFieldsValue({
          [name]: value,
          postDistrictRefId: undefined,
        })
      }
    } else if (name === "postDistrictRefId") {
      return value => {
        setdistrictRefId(value)
        setEnableEstate(false)
        formRef.current.setFieldsValue({
          [name]: value,
          estateRefId: undefined,
        })
      }
    } else if (name === "estateRefId") {
      return value => {
        setestateRefId(value)
        setEnableBuilding(false)
        formRef.current.setFieldsValue({
          [name]: value,
          buildingRefId: undefined,
        })
      }
    } else {
      return value => {
        formRef.current.setFieldsValue({
          [name]: value,
        })
      }
    }
  }

  const CheckBoxHandlerGenerator = (name, reverse = false) => e => {
    formRef.current.setFieldsValue({
      [name]: reverse ? !e.target.checked : e.target.checked,
    })

    if (name === "notFound" && !e.target.checked) {
      formRef.current.setFieldsValue({
        otherAddress: null,
      })
    }
    if (name === "elders" && !e.target.checked) {
      formRef.current.setFieldsValue({
        numOfElders: 0,
      })
    }
    if (name === "illness" && !e.target.checked) {
      formRef.current.setFieldsValue({
        numOfChronicIllnesses: 0,
      })
    }
    if (name === "pregancy" && !e.target.checked) {
      formRef.current.setFieldsValue({
        weeksOfPregnancy: 0,
      })
    }
  }

  const ChildAgeHanderGenerator = name => index => value => {
    const AgeCollect = formRef.current.getFieldValue(name)
      ? formRef.current.getFieldValue(name)
      : []
    AgeCollect[index] = value
    formRef.current.setFieldsValue({
      [name]: AgeCollect,
    })
  }

  return (
    <div style={{ minHeight: "100vh" }}>
      <SEO title="問卷調查" />
      <Form onFinish={checkAndSubmit} ref={formRef}>
        {page == 1 && (
          <>
            <h4>多謝參與是次調查 問卷約需時2分鐘</h4>
            <h4>
              資料將每星期更新一次 透視各區口罩存量
              並將資料提供予區議員及口罩捐助者作參考
            </h4>
            <h4>(*為必須填寫答案)</h4>
            <Page currentPage={page} page={1}>
              <Row>姓名</Row>
              <Form.Item name="fullname">
                <Input autoComplete="off" />
              </Form.Item>
              <Row>*性別</Row>
              <Form.Item name="gender">
                <Select onChange={HandlerGenerator("gender")}>
                  <Option value={1}>
                    <OptionText>男性</OptionText>
                  </Option>
                  <Option value={2}>
                    <OptionText>女性</OptionText>
                  </Option>
                  <Option value={9}>
                    <OptionText>不適用</OptionText>
                  </Option>
                </Select>
              </Form.Item>
              <Row>*出生年份</Row>
              <Form.Item name="yearOfBirth">
                <InputNumber
                  type="number"
                  min={1900}
                  max={2020}
                  defaultValue={1995}
                  onChange={HandlerGenerator("yearOfBirth")}
                />
              </Form.Item>
              <Row>*地域</Row>
              <Form.Item name="regionRefId">
                <Select onChange={HandlerGenerator("regionRefId")}>
                  {regionList.map(location => (
                    <Option value={location.refId} key={location.refId}>
                      <OptionText>{location.fullname}</OptionText>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Row>*地區</Row>
              <Form.Item name="postDistrictRefId">
                <Select
                  showSearch
                  onChange={HandlerGenerator("postDistrictRefId")}
                  optionFilterProp="fullname"
                  disabled={enableDistrict}
                  filterOption={(input, option) => {
                    return option.fullname.indexOf(input.toUpperCase()) >= 0
                  }}
                >
                  {districtList.map(location => (
                    <Option
                      value={location.refId}
                      key={location.refId}
                      fullname={location.fullname}
                    >
                      <OptionText>{`${location.fullname}`}</OptionText>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Row>*屋邨</Row>
              <Form.Item name="estateRefId">
                <Select
                  showSearch
                  onChange={HandlerGenerator("estateRefId")}
                  disabled={enableEstate}
                  optionFilterProp="fullname"
                  filterOption={(input, option) => {
                    return option.fullname.indexOf(input.toUpperCase()) >= 0
                  }}
                >
                  {estateList.map(location => (
                    <Option
                      value={location.refId}
                      key={location.refId}
                      fullname={location.fullname}
                    >
                      <OptionText>{location.fullname}</OptionText>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Row>大廈</Row>
              <Form.Item name="buildingRefId">
                <Select
                  onChange={HandlerGenerator("buildingRefId")}
                  disabled={enableBuilding}
                >
                  {buildingList.map(location => (
                    <Option value={location.refId} key={location.refId}>
                      <OptionText>{location.fullname}</OptionText>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="notFound" valuePropName="checked">
                <StyledCheckbox onChange={CheckBoxHandlerGenerator("notFound")}>
                  找不到地址嗎? 直接填
                </StyledCheckbox>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.notFound !== currentValues.notFound
                }
              >
                {({ getFieldValue }) => {
                  if (getFieldValue("notFound")) {
                    return (
                      <>
                        <Row>其他地址：</Row>
                        <Form.Item name="otherAddress">
                          <AutoComplete
                            value={autoCompleteValue}
                            options={autoCompleteList}
                            onSearch={debounce(onAutoCompSearch, 1000)}
                            onChange={debounce(onAutoCompChange, 1000)}
                            onSelect={onAutoCompSelect}
                          >
                            {autoCompOptions}
                          </AutoComplete>
                        </Form.Item>
                      </>
                    )
                  }
                }}
              </Form.Item>
              <Form.Item>
                <Row>
                  <Col style={{ justifyContent: "center" }} offset={8} span={8}>
                    1/3
                  </Col>
                  <Col
                    style={{ justifyContent: "flex-end" }}
                    offset={0}
                    span={8}
                  >
                    <StyledButton onClick={checkAndSubmit}>第二頁</StyledButton>
                  </Col>
                </Row>
              </Form.Item>
            </Page>
          </>
        )}
        {page === 2 && (
          <Page currentPage={page} page={2}>
            <h4>資料將每星期更新一次 透視各區口罩存量</h4>
            <h4>並將資料提供予區議員及口罩捐助者作參考</h4>
            <Row>*Q1. 請問你家中有多少家庭成員？</Row>
            <Form.Item
              name="familySize"
              rules={[
                {
                  required: true,
                  message: "請輸入家庭成員數量",
                },
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                onChange={HandlerGenerator("familySize")}
              />
            </Form.Item>
            <Row>*Q2. 請問你在家中的口罩存量還有多少? (個數)</Row>
            <Form.Item
              name="maskBalance"
              rules={[
                {
                  required: true,
                  message: "請輸入口罩存量",
                },
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                onChange={HandlerGenerator("maskBalance")}
              />
            </Form.Item>
            <Row>*Q3. 請問你家中每天需要消耗多少口罩？</Row>
            <Form.Item
              name="maskDailyConsumptionAmount"
              rules={[
                {
                  required: true,
                  message: "請輸入家中每日消耗口罩數量",
                },
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                onChange={HandlerGenerator("maskDailyConsumptionAmount")}
              />
            </Form.Item>
            <Row>*Q4. 你購買口罩（一盒50個）的平均價錢？</Row>
            <Form.Item
              name="priceOfMask"
              rules={[
                {
                  required: true,
                  message: "請輸輸入口罩存量",
                },
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                onChange={HandlerGenerator("priceOfMask")}
              />
            </Form.Item>
            <Row>*Q5. 你願意接受口罩（一盒50個）的平均價錢？</Row>
            <Form.Item
              name="idealPriceOfMask"
              rules={[
                {
                  required: true,
                  message: "請輸入願意接受的口罩價格",
                },
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                onChange={HandlerGenerator("idealPriceOfMask")}
              />
            </Form.Item>

            <Form.Item>
              <Row>
                <Col style={{ justifyContent: " flex-start" }} span={8}>
                  <StyledButton onClick={() => setPage(1)}>第一頁</StyledButton>
                </Col>
                <Col style={{ justifyContent: "center" }} offset={0} span={8}>
                  2/3
                </Col>
                <Col
                  style={{ justifyContent: " flex-end" }}
                  offset={0}
                  span={8}
                >
                  <StyledButton onClick={checkAndSubmit}>第三頁</StyledButton>
                </Col>
              </Row>
            </Form.Item>
          </Page>
        )}

        {page === 3 && (
          <Page currentPage={page} page={3}>
            <Row>
              *Q6. 請問你家中有沒有以下需要特別照顧的人士？（可選擇多項）
            </Row>
            <Form.Item name="elders" valuePropName="checked">
              <StyledCheckbox onChange={CheckBoxHandlerGenerator("elders")}>
                長者（65歲以上）
              </StyledCheckbox>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.elders !== currentValues.elders
              }
            >
              {({ getFieldValue }) => {
                if (getFieldValue("elders")) {
                  return (
                    <>
                      <Row>{`*Q6.1 長者（65歲以上）數量`}</Row>
                      <Form.Item
                        name={`numOfElders`}
                        rules={[
                          {
                            required: true,
                            message: "請輸入長者數量",
                          },
                        ]}
                      >
                        <InputNumber
                          type="number"
                          min={0}
                          onChange={HandlerGenerator(`numOfElders`)}
                        />
                      </Form.Item>
                    </>
                  )
                }
              }}
            </Form.Item>
            <Form.Item name="illness" valuePropName="checked">
              <StyledCheckbox onChange={CheckBoxHandlerGenerator("illness")}>
                長期病患者（需定期到醫院覆診)
              </StyledCheckbox>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.illness !== currentValues.illness
              }
            >
              {({ getFieldValue }) => {
                if (getFieldValue("illness")) {
                  return (
                    <>
                      <Row>{`*Q6.1 長期病患者（需定期到醫院覆診) 數量`}</Row>
                      <Form.Item
                        name={`numOfChronicIllnesses`}
                        rules={[
                          {
                            required: true,
                            message: "請輸入長期病患數量",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          type="number"
                          onChange={HandlerGenerator(`numOfChronicIllnesses`)}
                        />
                      </Form.Item>
                    </>
                  )
                }
              }}
            </Form.Item>
            <Form.Item name="noEldersOrIllness" valuePropName="checked">
              <StyledCheckbox
                onChange={CheckBoxHandlerGenerator("noEldersOrIllness")}
              >
                沒有
              </StyledCheckbox>
            </Form.Item>
            <Row>*Q7. 請問你家中有沒有孕婦？</Row>{" "}
            <Form.Item
              name="pregancy"
              rules={[
                {
                  required: true,
                  message: "請確定家中有沒有孕婦",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}> 有</Radio>
                <Radio value={false}> 沒有</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.pregancy !== currentValues.pregancy
              }
            >
              {({ getFieldValue }) => {
                if (getFieldValue("pregancy")) {
                  return (
                    <>
                      <Row>{`*Q7.1 家中孕婦懷孕週數 ？`}</Row>
                      <Form.Item name="weeksOfPregnancy">
                        <InputNumber
                          type="number"
                          min={0}
                          onChange={HandlerGenerator("weeksOfPregnancy")}
                        />
                      </Form.Item>
                    </>
                  )
                }
              }}
            </Form.Item>
            <Row>*Q8. 請問你家中有多少小童/幼童?</Row>
            <Form.Item
              name="numOfKids"
              rules={[
                {
                  required: true,
                  message: "請輸入家中小童數量",
                },
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                onChange={HandlerGenerator("numOfKids")}
              />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.numOfKids !== currentValues.numOfKids
              }
            >
              {({ getFieldValue }) => {
                const NumberOfKids = getFieldValue("numOfKids")
                const KidsAgeCollect = []
                for (let i = 0; i < NumberOfKids; i++) {
                  KidsAgeCollect.push(
                    <Form.Item key={i} name={`kid-${i + 1}`}>
                      <Row key={`row-${i}`}>{`*Q8.${i + 1} 請問小童${i +
                        1}多少歲？`}</Row>
                      <InputNumber
                        min={0}
                        type="number"
                        onChange={ChildAgeHanderGenerator(`agesOfKids`)(i)}
                      />
                    </Form.Item>
                  )
                }
                return KidsAgeCollect
              }}
            </Form.Item>
            <Row>*Q9. 請問家中有沒有醫護人員</Row>
            <Form.Item
              name="medicalStaffInFamily"
              rules={[
                {
                  required: true,
                  message: "請確定家中有沒有醫護人員",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>有</Radio>
                <Radio value={false}>沒有</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.medicalStaffInFamily !==
                currentValues.medicalStaffInFamily
              }
            >
              {({ getFieldValue }) => {
                return (
                  getFieldValue("medicalStaffInFamily") !== undefined &&
                  getFieldValue("medicalStaffInFamily") && (
                    <>
                      <Row>*Q9.1 請問家中醫護擔任甚麼角色?</Row>
                      <Form.Item name="pro">
                        <Row>
                          <Col span={10}>
                            <StyledCheckbox
                              onChange={CheckBoxHandlerGenerator(
                                "doctorInFamily"
                              )}
                            >
                              醫生
                            </StyledCheckbox>
                          </Col>
                          <Col span={14}>
                            <StyledCheckbox
                              onChange={CheckBoxHandlerGenerator(
                                "nurseInFamily"
                              )}
                            >
                              護士
                            </StyledCheckbox>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={10}>
                            <StyledCheckbox
                              onChange={CheckBoxHandlerGenerator(
                                "midwifeInFamily"
                              )}
                            >
                              助產士
                            </StyledCheckbox>
                          </Col>
                          <Col span={14}>
                            <StyledCheckbox
                              onChange={CheckBoxHandlerGenerator(
                                "pharmacistInFamily"
                              )}
                            >
                              藥劑師
                            </StyledCheckbox>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={10}>
                            <StyledCheckbox
                              onChange={CheckBoxHandlerGenerator(
                                "ambulanceInFamily"
                              )}
                            >
                              救護員
                            </StyledCheckbox>
                          </Col>
                          <Col span={14}>
                            <StyledCheckbox
                              onChange={CheckBoxHandlerGenerator(
                                "radiologistInFamily"
                              )}
                            >
                              放射師
                            </StyledCheckbox>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={10}>
                            <StyledCheckbox
                              onChange={CheckBoxHandlerGenerator(
                                "occupationalTherapistInFamily"
                              )}
                            >
                              職業治療師
                            </StyledCheckbox>
                          </Col>
                          <Col span={14}>
                            <StyledCheckbox
                              onChange={CheckBoxHandlerGenerator(
                                "physicalTherapistInFamily"
                              )}
                            >
                              物理治療師
                            </StyledCheckbox>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={10}>
                            <StyledCheckbox
                              onChange={CheckBoxHandlerGenerator(
                                "elderlyCareGiverInFamily"
                              )}
                            >
                              長者護理員
                            </StyledCheckbox>
                          </Col>
                          <Col span={14}>
                            <StyledCheckbox
                              onChange={CheckBoxHandlerGenerator(
                                "medicalCareGiverInFamily"
                              )}
                            >
                              於醫院任職之護理員
                            </StyledCheckbox>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={10}>
                            <StyledCheckbox
                              onChange={CheckBoxHandlerGenerator("haveOthers")}
                            >
                              其他
                            </StyledCheckbox>
                          </Col>
                        </Row>
                      </Form.Item>
                    </>
                  )
                )
              }}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.haveOthers !== currentValues.haveOthers
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("haveOthers") && (
                  <>
                    <Row>其他醫護職位：</Row>
                    <Form.Item name="otherInFamily">
                      <Input placeholder="請輸入職位" autoComplete="off" />
                    </Form.Item>
                  </>
                )
              }
            </Form.Item>
            <Row>Q10. 本月需要到醫院次數？</Row>
            <Form.Item name="numOfHospitalVisit">
              <InputNumber
                type="number"
                min={0}
                onChange={HandlerGenerator("numOfHospitalVisit")}
              />
            </Form.Item>
            <Row>Q11. 是否需要接受他人捐助口罩？</Row>
            <Form.Item name="needMaskDonation">
              <Select onChange={HandlerGenerator("needMaskDonation")}>
                <Option value={100}>
                  <OptionText>急缺，非常需要</OptionText>
                </Option>
                <Option value={75}>
                  <OptionText>需要</OptionText>
                </Option>
                <Option value={50}>
                  <OptionText>需要，但不接受捐助</OptionText>
                </Option>
                <Option value={25}>
                  <OptionText>暫時夠用，不需要</OptionText>
                </Option>
                <Option value={0}>
                  <OptionText>完全不需要</OptionText>
                </Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Row>
                <StyledCheckbox
                  onChange={CheckBoxHandlerGenerator(
                    "subscribeHealthUpdate",
                    true
                  )}
                >
                  本人同意SundayKiss每星期向本人發送相關消息及宣傳資訊，以保持是次調查數據更新度。
                </StyledCheckbox>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <StyledCheckbox
                  onChange={CheckBoxHandlerGenerator(
                    "subscribePromotion",
                    true
                  )}
                >
                  Sunday
                  Kiss只可在您的同意下使用從您收集的個人資料作推廣資訊用途。
                  如果您不希望收到我們的推廣性要約或信息，請在左邊的框中打勾。
                </StyledCheckbox>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col style={{ justifyContent: " flex-start" }} span={8}>
                  <StyledButton onClick={() => setPage(1)}>第二頁</StyledButton>
                </Col>
                <Col style={{ justifyContent: "center" }} span={8}>
                  3/3
                </Col>
                <Col style={{ justifyContent: "flex-end" }} span={8}>
                  <StyledButton htmlType="submit">提交</StyledButton>
                </Col>
              </Row>
            </Form.Item>
          </Page>
        )}
      </Form>
    </div>
  )
}

SurveyPage.propTypes = {}

SurveyPage.defaultProps = {}

export default SurveyPage
