import { Form as AntDForm } from "antd"
import styled from "styled-components"

const Form = styled(AntDForm)`
  background-color: transparent;
  padding: 1.5rem 0;
  margin: 0;
  border-radius: 1rem;
  width: 100%;
  height: fit-content;
  color: ${props => props.theme.color.fontColor.pinkColor4};
`

export { Form }
