import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { isLoggedIn, isSurveyCompleted } from "../utils/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  async function checkSurveyStatus() {
    const status = await isSurveyCompleted()
    if (status) {
      // navigate("/user/second-survey")
      typeof window !== "undefined" &&
        window.location.assign("/user/second-survey")
    } else {
      // navigate("/user/survey")
      typeof window !== "undefined" && window.location.assign("/user/survey")
    }
  }

  if (
    !isLoggedIn() &&
    location.pathname !== "/" &&
    location.pathname !== "/report/"
  ) {
    // If not loged in, redirect to the home page
    // navigate("/")
    typeof window !== "undefined" && window.location.assign("/")
    return null
  } else if (isLoggedIn() && location.pathname === "/") {
    checkSurveyStatus()
  }
  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default PrivateRoute
