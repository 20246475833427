import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import OverllStats from "./overall"
import DistrictStats from "./district"
import { Row, Col, Responsive } from "../../lib/ui"
import { GetReport } from "../../services"

const StatsWrapper = styled.div`
  width: 100%;
`
const DistrictStatsWrapper = styled.div`
  padding: 1rem 1rem;
  border-radius: 1rem;
  background: ${props => props.theme.color.bgColor.whiteColor1};
`
const HeaderWrapper = styled.div`
  color: ${props => props.theme.color.fontColor.pinkColor4};
  margin: 1rem 0;
`
const StyledHeader = styled.h2`
  margin-block-end: 0.5rem;
`
const UpdateDate = styled.h6`
  margin-block-end: 0.5rem;
`
const StyledDistrictHeader = styled.h3`
  color: ${props => props.theme.color.fontColor.pinkColor4};
  margin: 0.5rem 0;
`

const island = {
  southern: "南區",
  "central-and-western": "中西區",
  "wan-chai": "灣仔",
  eastern: "東區",
}
const kowloon = {
  "sham-shui-po": "深水埗",
  "kowloon-city": "九龍城",
  "kwun-tong": "觀塘",
  "wong-tai-sin": "黃大仙",
  "yau-tsim-mong": "油尖旺",
}
const newTerritories = {
  islands: "離島區",
  "sai-kung": "西貢區",
  north: "北區",
  "tai-po": "大埔",
  "yuen-long": "元朗",
  "kwai-tsing": "葵青",
  "sha-tin": "沙田",
  "tsuen-wan": "荃灣",
  "tuen-mun": "屯門",
}

const Report = () => {
  const [overallData, setOverallData] = useState({})
  const [islandData, setIslandData] = useState([])
  const [kowloonData, setKowloonData] = useState([])
  const [newTerritoriesData, setNewTerritoriesData] = useState([])
  const [updateDate, setUpdateDate] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetReport()
      if (response.status === 200 && response.data) {
        const islandDataHolder = []
        const kowloonDataHolder = []
        const newTerritoriesDataHolder = []
        const { report } = response.data
        setOverallData(report.overall)
        const districtsData = report.groupings.districts
        districtsData.map(district => {
          if (
            district.districtRefId &&
            Object.keys(kowloon).includes(district.districtRefId)
          ) {
            kowloonDataHolder.push(district)
          } else if (
            district.districtRefId &&
            Object.keys(island).includes(district.districtRefId)
          ) {
            islandDataHolder.push(district)
          } else if (
            district.districtRefId &&
            Object.keys(newTerritories).includes(district.districtRefId)
          ) {
            newTerritoriesDataHolder.push(district)
          } else {
            return district
          }
        })
        setUpdateDate(response.updateAt)
        setKowloonData(kowloonDataHolder)
        setIslandData(islandDataHolder)
        setNewTerritoriesData(newTerritoriesDataHolder)
      }
    }
    fetchData()
  }, [])
  const generateStats = (data, category) => {
    const { subReport, districtRefId } = data
    const specificData = subReport[category]
    return (
      <Col span={6} key={districtRefId}>
        <DistrictStats
          districtName={
            kowloon[districtRefId] ||
            island[districtRefId] ||
            newTerritories[districtRefId]
          }
          districtValue={specificData.total}
          trends={specificData.totalChanged}
        />
      </Col>
    )
  }
  return (
    <>
      <Responsive.Desktop>
        <Row>
          <Col span={24} style={{ padding: "0 1.5rem" }}>
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>購買口罩(一盒50個)平均價格</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.priceOfMask && overallData.priceOfMask.average
                }
                trends={
                  overallData.priceOfMask &&
                  overallData.priceOfMask.averageChanged
                }
              />
            </StatsWrapper>
          </Col>
          <Col span={24} style={{ padding: "0 1.5rem" }}>
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>購買口罩(一盒50個)理想價格</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.idealPriceOfMask &&
                  overallData.idealPriceOfMask.average
                }
                trends={
                  overallData.idealPriceOfMask &&
                  overallData.idealPriceOfMask.averageChanged
                }
              />
            </StatsWrapper>
          </Col>
        </Row>
        <Row>
          <Col
            span={8}
            style={{ alignItems: "flex-start", padding: "0 1.5rem" }}
          >
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計口罩存量</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.maskBalance && overallData.maskBalance.total
                }
                trends={
                  overallData.maskBalance &&
                  overallData.maskBalance.totalChanged
                }
              />
              <HeaderWrapper>
                <StyledHeader>每區口罩存量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "maskBalance")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "maskBalance")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "maskBalance")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col
            span={8}
            style={{ alignItems: "flex-start", padding: "0 1.5rem" }}
          >
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計日常口罩用量</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.maskDailyConsumptionAmount &&
                  overallData.maskDailyConsumptionAmount.total
                }
                trends={
                  overallData.maskDailyConsumptionAmount &&
                  overallData.maskDailyConsumptionAmount.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區日常口罩用量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "maskDailyConsumptionAmount")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "maskDailyConsumptionAmount")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "maskDailyConsumptionAmount")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col
            span={8}
            style={{ alignItems: "flex-start", padding: "0 1.5rem" }}
          >
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計長者總數</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={overallData.elder && overallData.elder.total}
                trends={overallData.elder && overallData.elder.totalChanged}
              />

              <HeaderWrapper>
                <StyledHeader>每區長者數量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "elder")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "elder")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "elder")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col
            span={8}
            style={{ alignItems: "flex-start", padding: "0 1.5rem" }}
          >
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計孕婦總數</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.prenancy && overallData.prenancy.total
                }
                trends={
                  overallData.prenancy && overallData.prenancy.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區孕婦數量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "prenancy")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "prenancy")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "prenancy")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col
            span={8}
            style={{ alignItems: "flex-start", padding: "0 1.5rem" }}
          >
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計醫護總數</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.medicalStaff && overallData.medicalStaff.total
                }
                trends={
                  overallData.medicalStaff &&
                  overallData.medicalStaff.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區醫護數量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "medicalStaff")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "medicalStaff")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "medicalStaff")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col
            span={8}
            style={{ alignItems: "flex-start", padding: "0 1.5rem" }}
          >
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計長期病患總數</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.chronicIllnesses &&
                  overallData.chronicIllnesses.total
                }
                trends={
                  overallData.chronicIllnesses &&
                  overallData.chronicIllnesses.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區長期病患數量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "chronicIllnesses")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "chronicIllnesses")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "chronicIllnesses")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
        </Row>
      </Responsive.Desktop>
      <Responsive.Tablet>
        <Row>
          <Col span={24} style={{ padding: "0 1.5rem" }}>
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>購買口罩(一盒50個)平均價格</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.priceOfMask && overallData.priceOfMask.average
                }
                trends={
                  overallData.priceOfMask &&
                  overallData.priceOfMask.averageChanged
                }
              />
            </StatsWrapper>
          </Col>
          <Col span={24} style={{ padding: "0 1.5rem" }}>
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>購買口罩(一盒50個)理想價格</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.idealPriceOfMask &&
                  overallData.idealPriceOfMask.average
                }
                trends={
                  overallData.idealPriceOfMask &&
                  overallData.idealPriceOfMask.averageChanged
                }
              />
            </StatsWrapper>
          </Col>
        </Row>
        <Row>
          <Col
            span={12}
            style={{ alignItems: "flex-start", padding: "0 1.5rem" }}
          >
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計口罩存量</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.maskBalance && overallData.maskBalance.total
                }
                trends={
                  overallData.maskBalance &&
                  overallData.maskBalance.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區口罩存量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "maskBalance")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "maskBalance")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "maskBalance")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col
            span={12}
            style={{ alignItems: "flex-start", padding: "0 1.5rem" }}
          >
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計日常口罩用量</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.maskDailyConsumptionAmount &&
                  overallData.maskDailyConsumptionAmount.total
                }
                trends={
                  overallData.maskDailyConsumptionAmount &&
                  overallData.maskDailyConsumptionAmount.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區日常口罩用量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "maskDailyConsumptionAmount")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "maskDailyConsumptionAmount")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "maskDailyConsumptionAmount")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col
            span={12}
            style={{ alignItems: "flex-start", padding: "0 1.5rem" }}
          >
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計長者總數</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={overallData.elder && overallData.elder.total}
                trends={overallData.elder && overallData.elder.totalChanged}
              />

              <HeaderWrapper>
                <StyledHeader>每區長者數量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "elder")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "elder")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "elder")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col
            span={12}
            style={{ alignItems: "flex-start", padding: "0 1.5rem" }}
          >
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計孕婦總數</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.prenancy && overallData.prenancy.total
                }
                trends={
                  overallData.prenancy && overallData.prenancy.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區孕婦數量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "prenancy")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "prenancy")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "prenancy")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col
            span={12}
            style={{ alignItems: "flex-start", padding: "0 1.5rem" }}
          >
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計醫護總數</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.medicalStaff && overallData.medicalStaff.total
                }
                trends={
                  overallData.medicalStaff &&
                  overallData.medicalStaff.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區醫護數量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "medicalStaff")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "medicalStaff")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "medicalStaff")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col
            span={12}
            style={{ alignItems: "flex-start", padding: "0 1.5rem" }}
          >
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計長期病患總數</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.chronicIllnesses &&
                  overallData.chronicIllnesses.total
                }
                trends={
                  overallData.chronicIllnesses &&
                  overallData.chronicIllnesses.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區長期病患數量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "chronicIllnesses")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "chronicIllnesses")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "chronicIllnesses")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
        </Row>
      </Responsive.Tablet>
      <Responsive.Mobile>
        <Row>
          <Col span={24}>
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>購買口罩(一盒50個)平均價格</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.priceOfMask && overallData.priceOfMask.average
                }
                trends={
                  overallData.priceOfMask &&
                  overallData.priceOfMask.averageChanged
                }
              />
            </StatsWrapper>
          </Col>
          <Col span={24}>
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>購買口罩(一盒50個)理想價格</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.idealPriceOfMask &&
                  overallData.idealPriceOfMask.average
                }
                trends={
                  overallData.idealPriceOfMask &&
                  overallData.idealPriceOfMask.averageChanged
                }
              />
            </StatsWrapper>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計口罩存量</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.maskBalance && overallData.maskBalance.total
                }
                trends={
                  overallData.maskBalance &&
                  overallData.maskBalance.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區口罩存量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "maskBalance")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "maskBalance")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "maskBalance")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col span={24}>
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計日常口罩用量</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.maskDailyConsumptionAmount &&
                  overallData.maskDailyConsumptionAmount.total
                }
                trends={
                  overallData.maskDailyConsumptionAmount &&
                  overallData.maskDailyConsumptionAmount.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區日常口罩用量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "maskDailyConsumptionAmount")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "maskDailyConsumptionAmount")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "maskDailyConsumptionAmount")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col span={24}>
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計長者總數</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={overallData.elder && overallData.elder.total}
                trends={overallData.elder && overallData.elder.totalChanged}
              />

              <HeaderWrapper>
                <StyledHeader>每區長者數量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "elder")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "elder")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "elder")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col span={24}>
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計孕婦總數</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.prenancy && overallData.prenancy.total
                }
                trends={
                  overallData.prenancy && overallData.prenancy.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區孕婦數量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "prenancy")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "prenancy")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "prenancy")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col span={24}>
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計醫護總數</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.medicalStaff && overallData.medicalStaff.total
                }
                trends={
                  overallData.medicalStaff &&
                  overallData.medicalStaff.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區醫護數量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "medicalStaff")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "medicalStaff")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "medicalStaff")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
          <Col span={24}>
            <StatsWrapper>
              <HeaderWrapper>
                <StyledHeader>累計長期病患總數</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <OverllStats
                OverallValue={
                  overallData.chronicIllnesses &&
                  overallData.chronicIllnesses.total
                }
                trends={
                  overallData.chronicIllnesses &&
                  overallData.chronicIllnesses.totalChanged
                }
              />

              <HeaderWrapper>
                <StyledHeader>每區長期病患數量概要</StyledHeader>
                <UpdateDate>
                  最後更新: {moment(updateDate).format("DD-MM-YYYY")}
                </UpdateDate>
              </HeaderWrapper>
              <DistrictStatsWrapper>
                <Row>
                  <StyledDistrictHeader>港島</StyledDistrictHeader>
                </Row>
                <Row>
                  {islandData.map(districtData =>
                    generateStats(districtData, "chronicIllnesses")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>九龍</StyledDistrictHeader>
                </Row>
                <Row>
                  {kowloonData.map(districtData =>
                    generateStats(districtData, "chronicIllnesses")
                  )}
                </Row>
                <Row>
                  <StyledDistrictHeader>新界</StyledDistrictHeader>
                </Row>
                <Row>
                  {newTerritoriesData.map(districtData =>
                    generateStats(districtData, "chronicIllnesses")
                  )}
                </Row>
              </DistrictStatsWrapper>
            </StatsWrapper>
          </Col>
        </Row>
      </Responsive.Mobile>
    </>
  )
}

export default Report
