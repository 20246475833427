import React from "react"
import Responsive from "react-responsive"
import PropTypes from "prop-types"
import { withTheme } from "styled-components"
import Theme from "../../../theme/default"

const BreakPoint = ({
  theme,
  name,
  children,
  breakpoint: breakpointProp,
  ...props
}) => {
  const { breakPoints } = theme
  const breakPoint = breakpointProp || breakPoints[name] || breakPoints.desktop

  return (
    <Responsive
      {...props}
      minWidth={breakPoint.minWidth}
      maxWidth={breakPoint.maxWidth}
    >
      {children}
    </Responsive>
  )
}

BreakPoint.propTypes = {
  theme: PropTypes.shape({
    breakPoints: PropTypes.object,
  }),
  name: PropTypes.string,
  children: PropTypes.object,
  breakpoint: PropTypes.shape({
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
  }),
}

BreakPoint.defaultProps = {
  theme: Theme,
}

const BreakPointWithTheme = withTheme(BreakPoint)

export default BreakPointWithTheme
