import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Statistic, CaretUpFilled, CaretDownFilled } from "../../lib/ui"

const StatsWrapper = styled.div`
  width: 100%;
`

const TrendsWrapper = styled.div`
  background-color: ${props => props.theme.color.bgColor.whiteColor1};
  text-align: center;
  color: ${props => {
    if (props.trends > 0) {
      return props.theme.color.fontColor.greenColor1
    } else if (props.trends === 0) {
      return props.theme.color.fontColor.grayColor3
    } else {
      return props.theme.color.fontColor.pinkColor3
    }
  }};
`

const DistrictStats = ({ districtName, districtValue, trends }) => (
  <StatsWrapper>
    <Statistic title={districtName} value={districtValue} isDistrict />
    <TrendsWrapper trends={trends}>
      {Math.sign(trends) >= 0 ? (
        trends === 0 ? (
          ""
        ) : (
          <CaretUpFilled />
        )
      ) : (
        <CaretDownFilled />
      )}
      {Math.abs(trends) === 0 ? "─" : Math.abs(trends)}
    </TrendsWrapper>
  </StatsWrapper>
)

DistrictStats.propTypes = {
  districtName: PropTypes.string,
  districtValue: PropTypes.number,
  trends: PropTypes.number,
}

DistrictStats.defaultProps = {
  districtName: "九龙塘",
  districtValue: 0,
  trends: 0,
}

export default DistrictStats
