import styled from "styled-components"
import { Input as AntDInput } from "antd"

const Input = styled(AntDInput)`
  border: 0px;
  width: 100%;
  box-sizing: border-box;
  color: ${props => props.theme.color.bgColor.pinkColor3};
  border: 0;
  &:focus {
    border: 0px;
    box-shadow: 0 0 0 0px rgba(251, 225, 229, 0.5);
  }
  &:hover {
    border: 0px;
    box-shadow: 0 0 0 0px rgba(251, 225, 229, 0.5);
  }
  &:active {
    border: 0px;
    box-shadow: 0 0 0 0px rgba(251, 225, 229, 0.5);
  }
  input.ant-input {
    color: ${props => props.theme.color.bgColor.pinkColor3} !important;
  }
  input.ant-input::placeholder {
    color: ${props => props.theme.color.bgColor.pinkColor3};
    font-weight: bold;
  }
  .ant-input-prefix {
    display: flex;
    align-items: center;
    margin-right: 11px;
  }
  span.ant-input-affix-wrapper span.ant-input-affix-wrapper:hover {
    border: 2px solid ${props => props.theme.color.bgColor.pinkColor1};
    box-shadow: 0 0 0 0px rgba(251, 225, 229, 0.5);
  }
  span.ant-input-affix-wrapper-focused {
    border: 2px solid ${props => props.theme.color.bgColor.pinkColor1};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px
      ${props => props.theme.color.bgColor.pinkColor2} inset;
    -webkit-text-fill-color: ${props => props.theme.color.bgColor.pinkColor1};
    -webkit-font-smoothing: antialiased;
  }
  span.ant-input-group-addon {
    cursor: pointer;
    padding: 0;
    border: 0;
    background-color: transparent;
  }
  span.ant-input-group-addon:hover {
    border: 0;
  }
`

export { Input }
