import React from "react"
import styled from "styled-components"
import { Responsive } from "../../lib/ui"

const FooterWrapper = styled.div`
  display: block;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 19px !important;
  font-size: 0.7rem;
  color: ${props => props.theme.color.fontColor.whiteColor1};
  background-color: ${props => props.theme.color.bgColor.pinkColor3};
`

const Footer = () => (
  <>
    <Responsive.Mobile>
      <FooterWrapper>
        SundayKiss 互助互罩地圖 © 2020.NEW MEDIA GROUP 版權所有
      </FooterWrapper>
    </Responsive.Mobile>
    <Responsive.Tablet>
      <FooterWrapper>
        SundayKiss 互助互罩地圖 © 2020.NEW MEDIA GROUP 版權所有
      </FooterWrapper>
    </Responsive.Tablet>
    <Responsive.Desktop>
      <FooterWrapper>
        SundayKiss 互助互罩地圖 © 2020.NEW MEDIA GROUP 版權所有
      </FooterWrapper>
    </Responsive.Desktop>
  </>
)

export default Footer
